// .App {}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img.rect16 {
  width: 16px;
  height: 16px;
}

img.rect32 {
  width: 32px;
  height: 32px;
}

img.rect48 {
  width: 48px;
  height: 48px;
}

img.rect72 {
  width: 72px;
  height: 72px;
}

table.simpletable {
  width: 100%;
  border-collapse: collapse;
}

table.simpletable tr td {
  text-align: center;
  border: 1px solid lightgray;
}

span.KvTag {
  border-radius: 1rem;
  background-color: lightgray;
  padding: 0.2rem 0.5rem;
}

// span.KvTag {}
// span.KvTag>span.key {}

span.KvTag>span.value {
  font-weight: bold;
}

// ColumnDiv
.columnDiv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: #f5f5f5;

  >._title {
    flex: 1;
    min-width: 100px;
  }

  >._body {
    flex: 2;
    min-width: 250px;
  }
}

// Consignment List
.consignmentListView {
  .consignmentSearchBox {
    border: 1px solid lightgray;
    padding: 0.5rem;

    .statusChip {
      margin-left: 0.3rem;
    }
  }
}

.consignmentDetail {}

.consignmentDialog {
  .columnDiv {}
}

.serviceDetail {
  ._imageThumbBox {
    display: flex;
    flex-wrap: wrap;

    gap: 10px;

    ._imageThumb {
      flex: 1;
      min-width: 17%;

      >div {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
        overflow: hidden;
        border: 1px solid black;

        >div.delete_btn {
          >span {
            position: absolute;
            right: 0;
            top: 0;
            background-color: gray;
            color: white;
            width: 12px;
            height: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
          }
        }

        >div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          >span.orange {
            color: orange;
          }

          >img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

// User list
.userListView {
  .searchDiv {
    margin-bottom: 1rem;

    .searchOptionList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .columnDiv {
        margin-right: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}

// UserInfoDialog
.userInfoDialog {
  .userInfoDialogContent {
    font-size: 0.7rem;
  }
}

// UserInfo
.userInfoPCLayout {
  display: flex;

  >.leftMenu {
    flex: 1;

    >.userName {
      font-size: 2rem;
      font-weight: bold;
    }

    >.menuBody {
      background-color: lightgray;
      padding: 0.5rem;

      >.menuItem {
        height: 3rem;
        display: flex;
        align-items: center;
        padding: 1rem;
        cursor: pointer;

        &.selected {
          background-color: white;
        }
      }
    }
  }

  >.rightBody {
    flex: 4;
  }
}


// UserDetailOrders
.UserDetailOrders {
  >.head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  >.body {
    >table {
      >thead {
        >tr {
          >th {
            background-color: #595757;
            color: white;
            padding: 0.2rem;
            min-width: 70px;
          }
        }
      }

      >tbody {
        >tr {
          >td {
            background-color: #f2f1f1;
            padding: 0.2rem;
          }
        }
      }
    }
  }
}

// simple1panel
.simple1panel {
  // margin: 1rem;
}

.simple1panel_head {
  background-color: #cbcbcb;
  padding: 1rem;
}

.simple1panel_body {
  background-color: #f2f1f1;
  padding: 1rem;
}

// Dialog less margin
.lessMarginDialogPaper {
  margin: 8px !important;
  width: calc(100% - 16px) !important;
}

// ItemSearchCondition
._itemSearchCondition {
  width: 100%;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  background-color: #f6f6f6;
  padding: 10px 5px;

  &.m>.condBox>.condItem {
    min-width: 100%;
  }

  >.condBox {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    row-gap: 0.5rem;
    margin: 0.5rem auto;

    >.condItem {
      background-color: #e3e3e3;
      padding: 0.3rem 1rem;
      border-radius: 1rem;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 400px;
      gap: 1rem;

      >.name {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }

      >.body {
        flex: 3;
        display: flex;
        align-items: center;
      }
    }
  }
}

// common
.noBreakWord {
  white-space: nowrap;
}

// App
.App .drawer {
  width: 240px;
  flex-shrink: 0;
}

// Calendar
.calendar {
  border-collapse: collapse;

  & .selectedDay {
    background-color: #f0f0f0;
  }

  & td {
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    vertical-align: top;
  }

  & .sun {
    color: red;
  }

  & .sat {
    color: blue;
  }

  & .upHeader th .CalNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3ba1a;
    height: 40px;
    border-radius: 20px;
    padding: 20px;
    font-size: 20px;
  }

  & .downHeader th {
    border-bottom: 1px solid black;
    height: 30px;
    font-size: 14px;
  }
}

// CustomTable
.customTable {
  &>thead {
    background-color: #595757;
    color: white;

    &>tr {
      height: 3rem;
    }
  }

  &>tbody {
    &>tr {
      &.evenRow {
        background-color: #e0e0e0;
      }

      height: 2rem;

      &>td {
        text-align: center;
      }
    }
  }
}

// ItemHCard
.itemHCard {
  margin: 5px;
  padding: 5px;
  background-color: lightgray;
  display: flex;
  justify-content: space-between;

  & .prop {
    white-space: nowrap;
  }
}

// UserHCard
.userHCard {
  margin: 5px;
  padding: 5px;
  background-color: lightgray;
  display: flex;
  justify-content: space-between;

  & .prop {
    white-space: nowrap;
  }
}

// Header
.header {
  padding-bottom: 3; //  theme.spacing(3),

  &>header {
    box-shadow: none;
    border-bottom: 1px black dashed;
  }
}


// MobileMainSlide
.mobileMainSlide {
  // position: relative;
  // width: 92.55319149vw; // calcPx(1044),
  // height: 106.0283688vw; // calcPx(1196),
  // height: 1196px;

  &>.slick-slider>.slick-dots {
    bottom: 10px;

    &>li {
      margin: 0;
      width: 12px;
    }

    &>li>button:before {
      color: #ffffff;
      opacity: 1;
    }

    &>li.slick-active>button:before {
      color: #929292;
    }
  }

  & .innerCI {
    position: relative;
    // width: 92.55319149vw; // calcPx(1044),
    // height: 106.0283688vw; // calcPx(1196),
    background-color: lightgray;

    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &>.overDiv {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: white;
      // padding: `${calcPx(40)} ${calcPx(40)} ${calcPx(120)} ${calcPx(40)}`,
      padding: 3.546099291vw 3.546099291vw 10.63829787vw 3.546099291vw;

      &>.overTitle {
        padding: 0 3.546099291vw; // `0 ${calcPx(40)}`,
        font-size: 10.63829787vw; // calcPx(120),
        font-weight: bold;
      }

      &>.overDescription {
        padding: 0 5.319148936vw; // `0 ${calcPx(60)}`,
        font-size: 4.432624113vw; // calcPx(50),
      }
    }
  }
}

// MobileSubSlide
.mobileSubSlide {
  position: relative;
  width: 92.55319149vw; // calcPx(1044),
  height: 33.68794326vw; // calcPx(380),

  &>.slick-slider>.slick-dots {
    bottom: 10px;

    &>li {
      margin: 0;
      width: 12px;
    }

    &>li>button:before {
      color: #ffffff;
      opacity: 1;
    }

    &>li.slick-active>button:before {
      color: #929292;
    }
  }

  & .innerCI {
    position: relative;
    width: 92.55319149vw; // calcPx(1044),
    height: 33.68794326vw; // calcPx(380),
    background-color: lightgray;

    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &>.overDiv {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: white;
      // padding: `${calcPx(40)} ${calcPx(40)} ${calcPx(120)} ${calcPx(40)}`,
      padding: 3.546099291vw 3.546099291vw 10.63829787vw 3.546099291vw;

      &>.overTitle {
        padding: 0 3.546099291vw; // `0 ${calcPx(40)}`,
        font-size: 10.63829787vw; // calcPx(120),
        display: flex;
        justify-content: center;
        font-weight: bold;
      }

      &>.overDescription {
        padding: 0 5.319148936vw; // `0 ${calcPx(60)}`,
        font-size: 4.432624113vw; // calcPx(50),
      }
    }
  }
}



// OrderedItemDialog
.orderedItemDialogContentBody {
  & ._panel {
    &>._title {
      font-weight: bold;
    }
  }
}

// NotificationTemplateView
.notificationTemplateView {
  & .outlined {
    border: 1px solid gray;
    padding: 10px;
  }
}

// OrderDetailView
.orderDetailView {
  & .row {
    background-color: #f5f5f5;
    margin: 5px;
    display: flex;
    padding-right: 10px;

    & .label {
      align-items: center;
      font-weight: bold;
      padding: 1em;
      font-size: 1.5em;
      display: flex;
    }

    & .content {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
}

// ConstantCustomSlide1Dialog
.constantCustomSlide1DialogRoot {
  display: flex;

  & .thumbBox {
    position: relative;
    min-width: 48px;
    min-height: 64px;
    width: 48px;
    height: 64px;
    background-color: lightgray;
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;

    &.selected {
      border: 1px solid gray;
    }

    & .thumbBox_content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(240, 240, 240, 0.2);
    }
  }
}

// ConstantCustomSlide2Dialog 
.constantCustomSlide2DialogRoot {
  display: flex;

  & .thumbBox {
    position: relative;
    min-width: 48px;
    min-height: 64px;
    width: 48px;
    height: 64px;
    background-color: lightgray;
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;

    &.selected {
      border: 1px solid gray;
    }

    & .thumbBox_content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(240, 240, 240, 0.2);
    }
  }
}

// ItemDetailPropertyInfo
.itemDetailPropertyInfo {
  & .MuiFilledInput-root .MuiFilledInput-input.MuiSelect-filled {
    padding-top: 8px;
    padding-bottom: 5px;
  }
}

// ItemDetailView
.itemDetailView {
  & .paragraph {
    background-color: #eeeeee;
  }

  & .editBody {
    display: flex;
    flex-direction: column;

    & .loadingPaper {
      pointer-events: none;
      opacity: 0.3;
    }

    & .row {
      background-color: #f5f5f5;

      &.developing {
        background-color: orange;
      }

      &.deprecated {
        background-color: gray;
      }

      margin: 5px;
      display: flex;
      padding-right: 10px;

      &.changed {
        background-color: pink;
      }

      & .label {
        align-items: center;
        font-weight: bold;
        padding: 1em;
        font-size: 1.5em;
        display: flex;
      }

      & .content {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}

// ItemListView
.itemListView {
  & .item_selected {
    background-color: lightgray;
  }
}

// LogListView
.logListView {
  & tr.selected {
    background-color: lightpink;
  }
}

// ReservationLimitCalendar
.reservationLimitCalendar {
  & .calendarTitle {
    display: flex;
    justify-content: space-around;
  }

  & .calendarBody {
    display: flex;

    & table {
      width: 100%;
      border-collapse: collapse;

      // border: '1px solid black',
      & th {
        border: 1px solid black;
      }

      & td {
        &.day {
          width: 100 / 7 + "%",
        }

        border: 1px solid black;
        text-align: center;
      }
    }
  }

  & div.selected {
    background-color: yellow;
  }
}

// ReservationView
.reservationView {
  & span.daytime {
    padding: 0 5px;
  }

  & .pastReservation {
    background-color: gray;
  }
}

// TextEditor - For Board
.textEditor-wrapper-class {}

.textEditor-editor-class {
  border: 1px solid lightgray;
  padding: 0.5rem;
}

.textEditor-toolbar-class {
  >.rdw-embedded-wrapper {
    display: none;
  }

  >.rdw-image-wrapper {
    display: none;
  }
}




.searchConditionBox {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  row-gap: 0.5rem;

  >.fieldItem {
    display: flex;
    align-items: center;
    min-width: 400px;

    >.fieldName {
      flex: 1;
      margin-right: 2rem;
      display: flex;
      justify-content: flex-end;
    }

    >.fieldBody {
      flex: 3;
      display: flex;
      align-items: center;
    }
  }
}

.uline {
  text-decoration: underline;
}

















table.grayStyle {
  border-collapse: collapse;
  border: 1px solid #aaa;
  // border: 0;

  th,
  td {
    border: 1px solid #aaa;
    background-clip: padding-box;
    scroll-snap-align: start;
    background-color: #f2f1f1;
  }

  tr.error td {
    background-color: #cc5555;
  }

  tbody tr:last-child th,
  tbody tr:last-child td {
    border-bottom: 0;
  }

  thead {
    z-index: 1000;
    position: relative;
  }

  th,
  td {
    padding: 0.6rem;
    min-width: 6rem;
    text-align: left;
    margin: 0;
  }

  thead th {
    position: sticky;
    top: 0;
    border-top: 0;
    background-clip: padding-box;


  }

  thead th.pin {
    left: 0;
    z-index: 1001;
    border-left: 0;
  }

  tbody th {
    background-clip: padding-box;
    border-left: 0;
  }

  tbody {
    z-index: 10;
    position: relative;
  }

  tbody th {
    position: sticky;
    left: 0;
  }

  thead th,
  tbody th {
    background-color: #595757;
    color: white;
  }
}